import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import Layout from '.'
import './layout.css'
import './bootstrap.scss'

const LayoutJSX = ({ children, title, description, location }) => {
  return (
    <Layout
      title={title}
      description={description}
      location={location}>
      <Container
        fluid
        style={{
          backgroundColor: '#1e1e1e',
          paddingLeft: '0',
          paddingRight: '0',
        }}
      >
        <Container
          style={{
            paddingTop: '3rem',
            paddingBottom: '3rem',
            backgroundColor: '#f8f8f8',
          }}
        >
          <h1>{title}</h1>
          {children}
        </Container>
      </Container>
    </Layout>
  )
}

LayoutJSX.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  location: PropTypes.any.isRequired
}

export default LayoutJSX
