import React from 'react'
import { Container } from 'reactstrap'

import LayoutJSX from '../layouts/layout-jsx'

const Contact = () => (
  <LayoutJSX
    title="Contactez-nous"
    description="Contactez l'école Cuu-Long Vo-Dao de Nice."
    location="/contact">
    <Container style={{ paddingBottom: '2rem' }}>
      <h3>
        Académie Martiale des 9 Dragons
        <br />
        École Cuu-Long Vo-Dao
      </h3>
      <span>25 bis, rue Gubernatis &mdash; 06000 Nice &mdash; France</span>
    </Container>
    <div
      className="embed-responsive embed-responsive-4by3"
      style={{ paddingBottom: '2rem' }}
    >
      <iframe
        title="Carte Google"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2884.4502459798878!2d7.2693027158916586!3d43.70119137911961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cddaa5cf85aa7d%3A0x1fc9397613b781db!2s25B+Rue+Gubernatis%2C+06000+Nice!5e0!3m2!1sfr!2sfr!4v1566490592812!5m2!1sfr!2sfr"
        className="embed-responsive-item"
        style={{ paddingBottom: '2rem' }}
      />
    </div>
    <Container style={{ paddingBottom: '2rem' }}>
      <h3>Pour tous renseignements complémentaires</h3>
      <strong>M. TRAN D.</strong>
      <p>Toutes disciplines enseignées dans la salle</p>
      <p>
        <strong>Téléphone : </strong><a href="tel:+33493138017">04.93.13.80.17</a>
        <br />
        <strong>E-mail : </strong><a href="mailto:9.dragons.nice@gmail.com">9.dragons.nice@gmail.com</a>
      </p>
      <hr />
      <strong>M. TRAN H.N.</strong>
      <p>Qi-Gong et Tai-Chi-Chuan</p>
      <p>
        <strong>Téléphone : </strong>06.62.73.66.34
        <br />
        <strong>E-mail : </strong><a href="mailto:hoaingoc.tran06@gmail.com">hoaingoc.tran06@gmail.com</a>
      </p>
    </Container>
  </LayoutJSX>
)

export default Contact
